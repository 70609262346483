import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const DropDownSommier = ({ intl, onMouseLeave }) => {
  return (
    <ProductsDropdownEl>
      <DropDownList>
        <DropProductsSection>
          <li>
            <Link onClick={onMouseLeave} to="/shop/lit-electrique/">
              Sommier zoné - électrique <sup>Nouveau</sup>
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/sommier-zone-original/?Size=90x190cm&Offer=Seul"
            >
              Sommier zoné - original
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/sommier-zone-bois-massif/?Size=140x190cm&Offer=Seul"
            >
              Sommier zoné - bois massif
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/sommier-zone-coffre/?Size=140x190cm&Offer=Seul"
            >
              Sommier zoné - coffre
            </Link>
          </li>
        </DropProductsSection>
      </DropDownList>
      <ImageWrapper>
        <ImageBox>
          <ImageUrl to="/shop/lit-electrique/">
            Sommier zoné - électrique
          </ImageUrl>
          <Image
            className="lazyload"
            data-src="https://static.percko.com/uploads/67b65e47-18e9-45cf-b3ac-8b4a971dcc70.jpg"
          />
          <TagWrapper>Nouveau</TagWrapper>
          <TextWrapper>Sommier zoné - électrique</TextWrapper>
        </ImageBox>
        <ImageBox>
          <ImageUrl to="/shop/sommier-zone-bois-massif/?Size=140x190cm&Offer=Seul">
            Sommier zoné - bois massif
          </ImageUrl>
          <Image
            className="lazyload"
            data-src="https://static.percko.com/uploads/2cd611b3-7540-4cd7-aac7-32aefbd5002b.jpg"
          />
          <TextWrapper>Sommier zoné - bois massif</TextWrapper>
        </ImageBox>
        <ImageBox>
          <ImageUrl to="/shop/sommier-zone-coffre/?Size=140x190cm&Offer=Seul">
            Sommier zoné - coffre
          </ImageUrl>
          <Image
            className="lazyload"
            data-src="https://static.percko.com/uploads/80e770dc-2073-4f8f-bc2d-5f1caa8ea0e8.jpg"
          />
          <TextWrapper>Sommier zoné - coffre</TextWrapper>
        </ImageBox>
      </ImageWrapper>
    </ProductsDropdownEl>
  )
}

const ProductsDropdownEl = styled.div`
  display: flex;
  background: #fff;
  padding: 20px 30px;

  @media (max-width: 1200px) {
    padding: 0px 0px 20px 50px;
  }
`

const DropDownList = styled.div`
  width: 100%;
  min-width: 270px;
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
`

const DropProductsSection = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px;

  li {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  li a sup {
    font-style: italic;
  }

  a {
    display: block;
    width: 100%;
    color: #000 !important;
    font-family: "Gotham Book";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    padding: 5px 10px;

    :hover {
      color: #000 !important;
      font-weight: bold;

      @media (max-width: 1200px) {
        font-weight: 500;
      }
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    display: none;
  }
`

const ImageBox = styled.div`
  width: 100%;
  max-width: 270px;
  display: flex;
  justify-content: center;
  object-fit: contain;
  position: relative;
  margin: 0px 25px 0px 0px;

  :last-of-type {
    margin-right: 0px;
  }
`

const ImageUrl = styled(Link)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  text-indent: -9999px;
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
`

const TagWrapper = styled.div`
  width: auto;
  background: #fff;
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  padding: 5px 10px;
  position: absolute;
  top: 25px;
  left: 0px;
`

const TextWrapper = styled.div`
  width: auto;
  background: #fff;
  font-family: "Museo";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  padding: 5px 10px;
  position: absolute;
  bottom: 20px;
`

export default injectIntl(DropDownSommier)
