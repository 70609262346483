import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import BlankStar from "../../../static/blank_star.png"
import GreenStar from "../../../static/green_star.png"
import HalfStar from "../../../static/half_star.png"

const StaticReviewSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StaticReviewSectionBox = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1.2;
  font-family: "Museo";
  text-transform: initial;
  cursor: pointer;
  text-decoration: none !important;
  color: ${({ section }) => (section ? "#fff" : "#262626")};

  :hover {
    color: ${({ section }) => (section ? "#fff" : "#262626")};
  }

  span {
    font-size: 14px;

    @media (max-width: 380px) {
      font-size: 13px;
    }
  }

  @media (max-width: 380px) {
    font-size: 16px;
  }
`

const ImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;

  @media (max-width: 380px) {
    margin: 0px 8px;
  }
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin: 0px 1px;
  max-width: 20px;

  @media (max-width: 380px) {
    max-width: 18px;
  }
`

const StaticReviewSection = ({ intl, section }) => {
  return (
    <StaticReviewSectionWrapper>
      <StaticReviewSectionBox
        href={
          intl.locale === "fr"
            ? "https://fr.trustpilot.com/review/www.percko.com"
            : intl.locale === "de"
            ? "https://de.trustpilot.com/review/www.percko.com"
            : intl.locale === "es"
            ? "https://es.trustpilot.com/review/www.percko.com"
            : "https://www.trustpilot.com/review/www.percko.com"
        }
        target="_blank"
        section={section}
      >
        {intl.locale === "es"
          ? "Excelente"
          : intl.locale === "de"
          ? "Hervorragend"
          : intl.locale === "en"
          ? "Excellent"
          : "Excellent"}
        <ImageBox>
          <Image className="lazyload" data-src={GreenStar} alt="" />
          <Image className="lazyload" data-src={GreenStar} alt="" />
          <Image className="lazyload" data-src={GreenStar} alt="" />
          <Image className="lazyload" data-src={GreenStar} alt="" />
          <Image className="lazyload" data-src={HalfStar} alt="" />
        </ImageBox>
        <span>
          {intl.locale === "es"
            ? "Más de 3000 opiniones"
            : intl.locale === "de"
            ? "Mehr als 3000 Bewertungen"
            : intl.locale === "en"
            ? "More than 3000 reviews"
            : "Plus de 3000 avis"}
        </span>
        {/* {intl.locale === "es"
          ? "1877 opiniones en"
          : intl.locale === "de"
          ? "1.877 Bewertungen auf"
          : intl.locale === "en"
          ? "1,877 reviews on"
          : "1 876 avis sur"}
        <Image className="lazyload" data-src={GreenStar} alt="" />
        Trustpilot */}
      </StaticReviewSectionBox>
    </StaticReviewSectionWrapper>
  )
}

export default injectIntl(StaticReviewSection)
