export const notEnUrls = [
  "les-maux-de-dos",
  "contact-sav",
  "filleul",
  "matelas",
  "oreiller",
  "offres",
  "lit-electrique",
  "oreillers-ergonomiques",
  "lyneup-new",
  "parrainage",
  "solutions-textiles-test",
  "showroom",
  "showroom-paris",
  "offres-exclusives",
  "chaise-bureau-ergonomique-exclusif",
  "solutions-textiles-exclusif",
  "matelas-exclusif",
  "sommiers-zones-exclusifs",
  "sommier-zone-original-exclusif",
  "sommier-zone-coffre-exclusif",
  "sommier-zone-bois-massif-exclusif",
  "lit-electrique-exclusif",
  "oreillers-ergonomiques-exclusif",
  "lyneup-enplus",
  "matelas-enplus",
  "oreiller-enplus",
  "offres-enplus",
  "sommiers-zones-enplus",
  "sommier-zone-original-enplus",
  "sommier-zone-coffre-enplus",
  "sommier-zone-bois-massif-enplus",
]

export const frenchOnlyURls = [
  "les-maux-de-dos",
  "contact-sav",
  "filleul",
  "matelas",
  "oreiller",
  "offres",
  "lit-electrique",
  "oreillers-ergonomiques",
  "lyneup-new",
  "parrainage",
  "solutions-textiles-test",
  "showroom",
  "showroom-paris",
  "offres-exclusives",
  "chaise-bureau-ergonomique-exclusif",
  "solutions-textiles-exclusif",
  "matelas-exclusif",
  "sommiers-zones-exclusifs",
  "sommier-zone-original-exclusif",
  "sommier-zone-coffre-exclusif",
  "sommier-zone-bois-massif-exclusif",
  "lit-electrique-exclusif",
  "oreillers-ergonomiques-exclusif",
  "lyneup-enplus",
  "matelas-enplus",
  "oreiller-enplus",
  "offres-enplus",
  "sommiers-zones-enplus",
  "sommier-zone-original-enplus",
  "sommier-zone-coffre-enplus",
  "sommier-zone-bois-massif-enplus",
]

export const notDeUrls = [
  "competition-rules",
  "les-maux-de-dos",
  "loop",
  "videoloop",
  "gifts",
  "idee-cadeau",
  "contact-sav",
  "filleul",
  "matelas",
  "oreiller",
  "offres",
  "lit-electrique",
  "oreillers-ergonomiques",
  "lyneup-new",
  "parrainage",
  "solutions-textiles-test",
  "showroom",
  "showroom-paris",
  "offres-exclusives",
  "chaise-bureau-ergonomique-exclusif",
  "solutions-textiles-exclusif",
  "matelas-exclusif",
  "sommiers-zones-exclusifs",
  "sommier-zone-original-exclusif",
  "sommier-zone-coffre-exclusif",
  "sommier-zone-bois-massif-exclusif",
  "lit-electrique-exclusif",
  "oreillers-ergonomiques-exclusif",
  "lyneup-enplus",
  "matelas-enplus",
  "oreiller-enplus",
  "offres-enplus",
  "sommiers-zones-enplus",
  "sommier-zone-original-enplus",
  "sommier-zone-coffre-enplus",
  "sommier-zone-bois-massif-enplus",
]

export const notEsUrls = [
  "competition-rules",
  "les-maux-de-dos",
  "loop",
  "videoloop",
  "gifts",
  "idee-cadeau",
  "contact-sav",
  "filleul",
  "matelas",
  "oreiller",
  "offres",
  "lit-electrique",
  "oreillers-ergonomiques",
  "lyneup-new",
  "parrainage",
  "solutions-textiles-test",
  "showroom",
  "showroom-paris",
  "offres-exclusives",
  "chaise-bureau-ergonomique-exclusif",
  "solutions-textiles-exclusif",
  "matelas-exclusif",
  "sommiers-zones-exclusifs",
  "sommier-zone-original-exclusif",
  "sommier-zone-coffre-exclusif",
  "sommier-zone-bois-massif-exclusif",
  "lit-electrique-exclusif",
  "oreillers-ergonomiques-exclusif",
  "lyneup-enplus",
  "matelas-enplus",
  "oreiller-enplus",
  "offres-enplus",
  "sommiers-zones-enplus",
  "sommier-zone-original-enplus",
  "sommier-zone-coffre-enplus",
  "sommier-zone-bois-massif-enplus",
]

export const frEnMapping = [
  { fr: "dispositif-medical", en: "scientific-study" },
  { fr: "notre-histoire", en: "our-story" },
  { fr: "solutions-textiles", en: "wearable-technology" },
  { fr: "ils-nous-aiment", en: "they-love-us" },
  { fr: "nos-revendeurs", en: "our-resellers" },
  { fr: "conditions-generales-de-vente", en: "terms-of-sale" },
  {
    fr: "livraison-et-politique-de-retour",
    en: "shipping-and-return-policy",
  },
  { fr: "mentions-legales", en: "legal-notice" },
  { fr: "mon-compte", en: "dashboard" },
  { fr: "commande", en: "orders" },
  { fr: "adresses", en: "edit-address" },
  { fr: "moyens-de-paiement", en: "payment-methods" },
  { fr: "details-du-compte", en: "edit-account" },
  { fr: "idee-cadeau", en: "gifts" },
  { fr: "jeuconcours", en: "competition-rules" },
]

export const langCurrLiterals = [
  /\/fr\//,
  /\/en\//,
  /\/de\//,
  /\/es\//,
  /\/eur\//,
  /\/gbp\//,
  /\/usd\//,
  /\/cad\//,
]

export const hasFrenchOnlyUrl = url => {
  let hasFr = false
  frenchOnlyURls.forEach(element => {
    if (url.includes(element)) {
      hasFrenchOnly = true
    }
  })
  return hasFr
}

export const urlNotInLocale = ({ url = "", notUrlsArr = [] } = {}) => {
  let notILocale = false
  notUrlsArr.forEach(element => {
    if (url.includes(element)) notILocale = true
  })
  return notILocale
}

export const removeExtraLiterals = path => {
  let tempPath = path
  langCurrLiterals.forEach(element => {
    tempPath = tempPath.replace(element, "/")
  })
  return tempPath
}

export const handleFrEnMapping = ({ url = "", locale = "en" } = {}) => {
  let tempUrl = url
  if (locale === "fr") {
    frEnMapping.forEach(element => {
      if (tempUrl.includes(element.en))
        tempUrl = url.replace(element.en, element.fr)
    })
  } else {
    frEnMapping.forEach(element => {
      if (url.includes(element.fr))
        tempUrl = url.replace(element.fr, element.en)
    })
  }
  return tempUrl
}

export const handleCurrRedirects = ({
  newCurr = "",
  pathname = "",
  origin = "",
  search = "",
} = {}) => {
  let returnUrl = ""
  switch (newCurr) {
    case "eur":
      if (pathname.includes("/en/")) {
        returnUrl =
          origin +
          "/eur/en" +
          removeExtraLiterals(
            handleFrEnMapping({
              locale: "en",
              url: pathname,
            })
          ) +
          search
      } else if (pathname.includes("/de/")) {
        if (urlNotInLocale({ url: pathname, notUrlsArr: notDeUrls }))
          returnUrl = origin + "/eur/de/"
        else
          returnUrl =
            origin +
            "/eur/de" +
            removeExtraLiterals(
              handleFrEnMapping({
                locale: "de",
                url: pathname,
              })
            ) +
            search
      } else if (pathname.includes("/es/")) {
        if (urlNotInLocale({ url: pathname, notUrlsArr: notEsUrls }))
          returnUrl = origin + "/eur/es/"
        else
          returnUrl =
            origin +
            "/eur/es" +
            removeExtraLiterals(
              handleFrEnMapping({
                locale: "es",
                url: pathname,
              })
            ) +
            search
      } else
        returnUrl =
          origin +
          "/eur/fr" +
          removeExtraLiterals(
            handleFrEnMapping({
              locale: "fr",
              url: pathname,
            })
          ) +
          search
      break
    case "gbp":
      if (urlNotInLocale({ url: pathname, notUrlsArr: notEnUrls }))
        returnUrl = origin + "/gbp/en/"
      else
        returnUrl =
          origin +
          "/gbp/en" +
          removeExtraLiterals(
            handleFrEnMapping({
              locale: "en",
              url: pathname,
            })
          ) +
          search
      break
    case "usd":
      if (urlNotInLocale({ url: pathname, notUrlsArr: notEnUrls }))
        returnUrl = origin + "/usd/en/"
      else
        returnUrl =
          origin +
          "/usd/en" +
          removeExtraLiterals(
            handleFrEnMapping({
              locale: "en",
              url: pathname,
            })
          ) +
          search
      break
    case "cad":
      if (pathname.includes("/en/")) {
        if (urlNotInLocale({ url: pathname, notUrlsArr: notEnUrls }))
          returnUrl = origin + "/cad/en/"
        else
          returnUrl =
            origin +
            "/cad/en" +
            removeExtraLiterals(
              handleFrEnMapping({
                locale: "en",
                url: pathname,
              })
            ) +
            search
      } else {
        returnUrl =
          origin +
          "/cad/fr" +
          removeExtraLiterals(
            handleFrEnMapping({
              locale: "fr",
              url: pathname,
            })
          ) +
          search
      }
      break
  }
  return returnUrl
}

export const handelLangRedirects = ({
  locale = "",
  origin = "",
  pathname = "",
  search = "",
} = {}) => {
  let returnUrl = ""
  let currency = sessionStorage.getItem("selectedCountry")
  switch (locale) {
    case "fr":
      if (currency === "cad") {
        returnUrl =
          origin +
          "/cad/fr" +
          removeExtraLiterals(
            handleFrEnMapping({
              locale: "fr",
              url: pathname,
            })
          ) +
          search
      } else {
        returnUrl =
          origin +
          "/eur/fr" +
          removeExtraLiterals(
            handleFrEnMapping({
              locale: "fr",
              url: pathname,
            })
          ) +
          search
      }
      break
    case "en":
      if (currency === "cad") {
        if (urlNotInLocale({ url: pathname, notUrlsArr: notEnUrls }))
          returnUrl = origin + "/cad/en"
        else {
          returnUrl =
            origin +
            "/cad/en" +
            removeExtraLiterals(
              handleFrEnMapping({
                locale: "en",
                url: pathname,
              })
            ) +
            search
        }
      } else {
        if (urlNotInLocale({ url: pathname, notUrlsArr: notEnUrls }))
          returnUrl = origin + "/gbp/en"
        else {
          returnUrl =
            origin +
            "/gbp/en" +
            removeExtraLiterals(
              handleFrEnMapping({
                locale: "en",
                url: pathname,
              })
            ) +
            search
        }
      }
      break
    case "de":
      if (urlNotInLocale({ url: pathname, notUrlsArr: notDeUrls }))
        returnUrl = origin + "/eur/de"
      else {
        returnUrl =
          origin +
          "/eur/de" +
          removeExtraLiterals(
            handleFrEnMapping({
              locale: "de",
              url: pathname,
            })
          ) +
          search
      }
      break
    case "es":
      if (urlNotInLocale({ url: pathname, notUrlsArr: notEsUrls }))
        returnUrl = origin + "/eur/es"
      else {
        returnUrl =
          origin +
          "/eur/es" +
          removeExtraLiterals(
            handleFrEnMapping({
              locale: "es",
              url: pathname,
            })
          ) +
          search
      }
      break
  }
  return returnUrl
}

export const frenchToEnUrl = url => {}
