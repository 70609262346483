import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const DropDownShop = ({ intl, onMouseLeave }) => {
  return (
    <ProductsDropdownEl>
      <DropDownList>
        <DropProductsSection>
          <ListTitle>Bureau</ListTitle>
          <li>
            <Link onClick={onMouseLeave} to="/shop/chaise-bureau-ergonomique/">
              Chaise ergonomique <sup>Nouveau</sup>
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/chaise-gaming/">
              Chaise gaming <sup>Nouveau</sup>
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/loop/">
              Roue de massage
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/solutions-textiles/">
              T-shirt discret <sup>Best-seller</sup>
            </Link>
          </li>
        </DropProductsSection>
        <MenuSeparator />
        <DropProductsSection>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/sommiers-zones/"
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              Lits pour le dos
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/matelas-hybride-ressorts-ensaches/"
            >
              Matelas hybride premium <sup>Best-seller</sup>
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/matelas-mousse-memoire-de-forme/"
            >
              Matelas mousse mémoire de forme
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/surmatelas/">
              Surmatelas réversible
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/lit-electrique/">
              Sommier zoné - électrique <sup>Nouveau</sup>
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/sommier-zone-original/?Size=90x190cm&Offer=Seul"
            >
              Sommier zoné - original
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/sommier-zone-bois-massif/?Size=140x190cm&Offer=Seul"
            >
              Sommier zoné - bois massif
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/sommier-zone-coffre/?Size=140x190cm&Offer=Seul"
            >
              Sommier zoné - coffre
            </Link>
          </li>
        </DropProductsSection>
        <MenuSeparator />
        <DropProductsSection>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/oreillers-ergonomiques/"
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              Oreillers
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/oreillers-ergonomiques/">
              Oreiller ergonomique universel
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/oreillers-ergonomiques/?Oreiller_plat=1"
            >
              Oreiller ergonomique plat <sup>Nouveau</sup>
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/oreillers-ergonomiques/?Oreiller_dorsalplus=1"
            >
              Oreiller ergonomique dorsal+ <sup>Nouveau</sup>
            </Link>
          </li>
        </DropProductsSection>
        <MenuSeparator />
        <DropProductsSection>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/solutions-textiles/"
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              Solutions textiles
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/solutions-textiles/tshirt-discret/?Gender=Female&Collar=V&Color=Rose%20poudr%C3%A9&Size=&Offer=Seul"
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              Femme
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/solutions-textiles/tshirt-discret/?Gender=Female&Collar=V&Color=Rose%20poudr%C3%A9&Size=&Offer=Seul"
            >
              T-shirt discret <sup>Best-seller</sup>
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/solutions-textiles/tshirt-sport/?Gender=Female&Collar=Rond&Color=Noir&Size=&Offer=Seul"
            >
              T-shirt de sport
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/solutions-textiles/gilet-pratique/?Gender=Female&Collar=Rond&Color=Noir&Size=&Offer=Seul"
            >
              Gilet pratique
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/solutions-textiles/tshirt-discret/?Gender=Male&Collar=V&Color=Sable&Size=&Offer=Seul"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Homme
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/solutions-textiles/tshirt-discret/?Gender=Male&Collar=V&Color=Sable&Size=&Offer=Seul"
            >
              T-shirt discret <sup>Best-seller</sup>
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/solutions-textiles/tshirt-sport/?Gender=Male&Collar=Rond&Color=Noir&Size=&Offer=Seul"
            >
              T-shirt de sport
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/solutions-textiles/gilet-pratique/?Gender=Male&Collar=Rond&Color=Noir&Size=&Offer=Seul"
            >
              Gilet pratique
            </Link>
          </li>
        </DropProductsSection>
        <MenuSeparator />
        <DropProductsSection>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/les-maux-de-dos/les-maux-de-dos-au-quotidien/"
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              Mal de dos
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/matelas/?Size=80x200cm&Type=hybride&Seul=1"
            >
              Matelas ferme
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/matelas-mousse-memoire-de-forme/"
            >
              Matelas mémoire de forme
            </Link>
          </li>
          <li>
            <Link
              onClick={onMouseLeave}
              to="/shop/matelas-hybride-ressorts-ensaches/"
            >
              Matelas ressorts ensachés
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/solutions-textiles/">
              Correcteur de posture
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/oreillers-ergonomiques/">
              Oreiller cervicales
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/oreillers-ergonomiques/">
              Oreiller mémoire de forme
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/loop/">
              Roue de massage
            </Link>
          </li>
          <li>
            <Link onClick={onMouseLeave} to="/shop/chaise-bureau-ergonomique/">
              Chaise de bureau <sup>Nouveau</sup>
            </Link>
          </li>
        </DropProductsSection>
      </DropDownList>
    </ProductsDropdownEl>
  )
}

const ProductsDropdownEl = styled.div`
  display: flex;
  background: #fff;
  padding: 20px 30px;

  @media (max-width: 1200px) {
    padding: 0px 0px 20px 50px;
  }
`

const DropDownList = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const DropProductsSection = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0px;

  li {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  li a sup {
    font-style: italic;
  }

  a {
    display: block;
    width: 100%;
    color: #000 !important;
    font-family: "Gotham Book";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    padding: 5px 10px;

    :hover {
      color: #000 !important;
      font-weight: bold;

      @media (max-width: 1200px) {
        font-weight: 500;
      }
    }
  }
`

const MenuSeparator = styled.div`
  width: 100%;
  max-width: 220px;
  display: none;
  border-bottom: 2px solid rgb(236, 236, 236);
  margin: 5px 0px 10px;

  @media (max-width: 1200px) {
    display: block;
  }
`

const ListTitle = styled.li`
  display: block;
  width: 100%;
  color: #000 !important;
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  padding: 5px 10px;
`

export default injectIntl(DropDownShop)
