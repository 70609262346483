import React, { useState, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
import { useLocation } from "@reach/router"
import { FaHeart } from "react-icons/fa"
import { mobileStickyMenuScript } from "../../../utils/additionalScriptHelpers"

const StickyMobileMenuWrapper = styled.div`
  width: 100%;
  padding: 0px;
  font-weight: bold;
  text-align: center;
  background: #fff;
  position: relative;
  z-index: 2;
  display: none;

  @media (max-width: 550px) {
    display: flex;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: scroll;
`

const MenuContainer = styled.div`
  width: 100%;
  // min-width: ${({ lang }) => (lang === "en" ? "485px" : "570px")};
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px;

  .card-inactive {
    opacity: 0.4;
  }
`

const MobileMenuLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #262626;
  cursor: pointer;
  margin: 0px 5px;
  min-width: 68px;

  :hover {
    color: #262626;
  }
`

const MobileMenuImage = styled.img`
  width: 100%;
  max-width: 60px;
  object-fit: contain;
  margin: 0px;
`

const MobileMenuText = styled.div`
  font-size: 12px;
  line-height: 1;
  font-family: "Gotham Book";
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
`

const StickyMobileMenu = ({ intl }) => {
  const [DmHide, setDmHide] = useState(false)
  var regex = /(scientific-study|dispositif-medical)/
  const container = useRef(null)

  const { pathname } = useLocation()

  function isInViewport(el) {
    let result = []
    for (var i = 0; i < el.length; i++) {
      const rect = el[i].getBoundingClientRect()
      result.push(
        rect.left >= 0 &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
      )
    }
    return result
  }

  function imageBlur() {
    let card = document.getElementsByClassName("card")
    let result = isInViewport(card)
    for (var i = 0; i < result.length; i++) {
      if (!result[i]) {
        card[i].classList.add("card-inactive")
      } else card[i].classList.remove("card-inactive")
    }
  }

  useEffect(() => {
    if (container.current) {
      container.current.addEventListener(
        "scroll",
        function() {
          imageBlur()
        },
        { passive: true }
      )
    }
  }, [container])

  useEffect(() => {
    if (isBrowser) {
      imageBlur()
    }
  }, [])

  useEffect(() => {
    if (isBrowser) {
      setDmHide(regex.test(pathname))
    }
  }, [pathname])
  return (
    <StickyMobileMenuWrapper>
      <Container ref={container}>
        <MenuContainer lang={intl.locale}>
          <MobileMenuLink
            className="card"
            to="/shop/lyneup/"
            onClick={() => mobileStickyMenuScript("Quotidien")}
          >
            <MobileMenuImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/97032f98-3226-4f92-97ef-e1a257088c26.png"
              alt="Lyne UP"
            />
            <MobileMenuText>
              {intl.locale === "en" ? "Everyday" : "Quotidien"}
            </MobileMenuText>
          </MobileMenuLink>
          <MobileMenuLink
            className="card"
            to={
              process.env.GATSBY_CURRENCY_TYPE === "eur" && intl.locale === "fr"
                ? "/shop/solutions-textiles/gilet-pratique/?Gender=Female"
                : "/shop/lynehome/"
            }
            onClick={() => mobileStickyMenuScript("Maison")}
          >
            <MobileMenuImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/2d2a5ca9-8805-4099-a195-278cdc3f175d.png"
            />
            <MobileMenuText>
              {intl.locale === "en" ? "Home" : "Maison"}
            </MobileMenuText>
          </MobileMenuLink>
          <MobileMenuLink
            className="card"
            to="/shop/loop/"
            onClick={() => mobileStickyMenuScript("Massage")}
          >
            <MobileMenuImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/409a5cea-c41a-45ba-8728-52f3e46629c5.png"
              alt="Loop"
            />
            <MobileMenuText>
              {intl.locale === "en" ? "Massage" : "Massage"}
            </MobileMenuText>
          </MobileMenuLink>
          {!DmHide && (
            <MobileMenuLink
              className="card"
              to={
                intl.locale === "en"
                  ? "/scientific-study"
                  : "/dispositif-medical"
              }
              onClick={() => mobileStickyMenuScript("Efficacité")}
            >
              <MobileMenuImage
                className="lazyload"
                data-src="https://static.percko.com/uploads/1cf95693-43c1-40f8-bdf3-e6e42bf387c4.png"
                alt={
                  intl.locale === "en"
                    ? "Scientific study"
                    : "Dispositif médical"
                }
              />
              <MobileMenuText>
                {intl.locale === "en" ? "Efficacy" : "Efficacité"}
              </MobileMenuText>
            </MobileMenuLink>
          )}
          <MobileMenuLink
            className="card"
            to={intl.locale === "en" ? "/gifts" : "/idee-cadeau"}
            onClick={() => mobileStickyMenuScript("Cadeaux")}
          >
            <MobileMenuImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/1fed1c92-9c8e-4968-ac54-aebf9685e80a.png"
              alt={intl.locale === "en" ? "Gifts" : "Idée cadeau"}
            />
            <MobileMenuText>
              {intl.locale === "en" ? "Gifts" : "Cadeaux"}
            </MobileMenuText>
          </MobileMenuLink>
          {DmHide && (
            <MobileMenuLink
              className="card"
              to={intl.locale === "en" ? "/they-love-us" : "/ils-nous-aiment"}
              onClick={() => mobileStickyMenuScript("Ils nous")}
            >
              <MobileMenuImage
                className="lazyload"
                data-src="https://static.percko.com/uploads/1fdd62f0-4a25-4056-b93c-8f23a06a6300.png"
                alt={intl.locale === "en" ? "They love us" : "Ils nous aiment"}
              />
              {intl.locale === "en" && (
                <MobileMenuText>
                  {"They"}
                  <FaHeart
                    color="#e34a34"
                    size="12px"
                    style={{ margin: "0px 3px" }}
                  />
                  {"us"}
                </MobileMenuText>
              )}
              {intl.locale != "en" && (
                <MobileMenuText>
                  {"Ils nous "}
                  <FaHeart
                    color="#e34a34"
                    size="12px"
                    style={{ margin: "0px 0px 0px 3px" }}
                  />
                </MobileMenuText>
              )}
            </MobileMenuLink>
          )}
          <MobileMenuLink
            className="card"
            to={
              process.env.GATSBY_CURRENCY_TYPE === "eur" && intl.locale === "fr"
                ? "/shop/solutions-textiles/tshirt-sport/?Gender=Female&Collar=Rond&Color=Noir&Size=&Offer=Seul"
                : "/shop/lynefit/"
            }
            onClick={() => mobileStickyMenuScript("Sport")}
          >
            <MobileMenuImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/0a805181-51f7-4746-8a62-224a0868c9de.png"
              alt="Lyne Fit"
            />
            <MobileMenuText>
              {intl.locale === "en" ? "Sports" : "Sport"}
            </MobileMenuText>
          </MobileMenuLink>
          {!DmHide && (
            <MobileMenuLink
              className="card"
              to={intl.locale === "en" ? "/they-love-us" : "/ils-nous-aiment"}
              onClick={() => mobileStickyMenuScript("Ils nous")}
            >
              <MobileMenuImage
                className="lazyload"
                data-src="https://static.percko.com/uploads/1fdd62f0-4a25-4056-b93c-8f23a06a6300.png"
                alt={intl.locale === "en" ? "They love us" : "Ils nous aiment"}
              />
              {intl.locale === "en" && (
                <MobileMenuText>
                  {"They"}
                  <FaHeart
                    color="#e34a34"
                    size="12px"
                    style={{ margin: "0px 3px" }}
                  />
                  {"us"}
                </MobileMenuText>
              )}
              {intl.locale != "en" && (
                <MobileMenuText>
                  {"Ils nous "}
                  <FaHeart
                    color="#e34a34"
                    size="12px"
                    style={{ margin: "0px 0px 0px 3px" }}
                  />
                </MobileMenuText>
              )}
            </MobileMenuLink>
          )}
        </MenuContainer>
      </Container>
    </StickyMobileMenuWrapper>
  )
}

export default injectIntl(StickyMobileMenu)
