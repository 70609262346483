export const colorsLyneHome = {
  fr: { BLEU: "BLUE" },
  en: { BLUE: "BLUE" },
  es: { AZUL: "BLUE" },
  de: { BLAU: "BLUE" },
}

export const gendersLyneHome = {
  fr: { Homme: "Male", Femme: "Female" },
  en: { Male: "Male", Female: "Female" },
  es: { Hombre: "Male", Mujer: "Female" },
  de: { Männer: "Male", Frauen: "Female" },
}

export const genderReverseLyneHome = {
  fr: { Male: "Homme", Female: "Femme" },
  en: { Male: "Male", Female: "Female" },
  es: { Male: "Hombre", Female: "Mujer" },
  de: { Male: "Männer", Female: "Frauen" },
}

export const colorsReverseLyneHome = {
  fr: { BLUE: "BLEU" },
  en: { BLUE: "BLUE" },
  es: { BLUE: "AZUL" },
  de: { BLUE: "BLAU" },
}

export const imageSliderLyneHome = {
  Default: [
    "https://static.percko.com/uploads/3afbcd91-dd52-4ad9-af33-aaefe2a1d41a.jpg",
    "https://static.percko.com/uploads/a73a2792-1d77-41f3-9010-b3f1f0395b16.jpg",
    "https://static.percko.com/uploads/46f2ea34-be26-4320-8bb2-9b07528786cb.jpg",
    "https://static.percko.com/uploads/a32582ea-f134-4526-8ac1-caee555531a2.jpg",
    "https://static.percko.com/uploads/f7375ed4-4025-46ac-b143-c1453d59c404.jpg",
    "https://static.percko.com/uploads/bc9d9099-6b50-4bb0-9296-00816fd4dcf6.jpg",
    "https://static.percko.com/uploads/e4728935-d161-453b-a9e2-44079199a5bf.jpg",
    "https://static.percko.com/uploads/96de0ecb-8ef1-401e-a6ad-f7ebcfb333c9.jpg",
    "https://static.percko.com/uploads/5a2aa3d4-a0aa-4ec5-b2ca-8fa831be0ed6.jpg",
  ],
  Male: {
    BLUE: [
      "https://static.percko.com/uploads/cd7ab4cf-351b-4812-928a-b01411ed58e9.jpg",
      "https://static.percko.com/uploads/5d2f9d6e-4471-4e28-8ae1-23e25a7f3062.jpg",
      "https://static.percko.com/uploads/24c77444-4358-449d-ba21-3f7a08d169c4.jpg",
      "https://static.percko.com/uploads/7e2a251e-173b-4bcb-8037-feb401c5d6a4.jpg",
      "https://static.percko.com/uploads/fda7353d-ec95-41db-bce8-252d17347765.jpg",
      "https://static.percko.com/uploads/52cee70f-f294-4b33-bf17-02816464c89d.jpg",
      "https://static.percko.com/uploads/e396f689-2bc0-4e3f-a476-638aaa521504.jpg",
      "https://static.percko.com/uploads/cd2c5ae0-d1b4-463f-94ad-d87e19bf78d2.jpg",
      "https://static.percko.com/uploads/4aee7b10-2bf0-4bd7-86ad-01b70ebbd002.jpg",
      // "https://static.percko.com/uploads/ec12a927-7085-4241-ac58-1a1112daecda.jpg",
    ],
  },
  Female: {
    BLUE: [
      "https://static.percko.com/uploads/1cf33af6-1c28-4a0e-b296-0aaffa97b813.jpg",
      "https://static.percko.com/uploads/ab1d8a49-8363-41da-b6ec-288538312aeb.jpg",
      "https://static.percko.com/uploads/9e124efa-fbad-414c-b313-b708faaff289.jpg",
      "https://static.percko.com/uploads/052e71df-2593-4056-ac25-e65d9d684b5c.jpg",
      "https://static.percko.com/uploads/9680ac3b-1a3a-419d-ba29-0b7900e8ec68.jpg",
      "https://static.percko.com/uploads/29cf5f4b-4e9f-4f93-96b7-7463f937ecca.jpg",
      "https://static.percko.com/uploads/76227744-5621-41ff-a7c6-ab6704b528ab.jpg",
      "https://static.percko.com/uploads/257c7ef3-ae2b-4a16-85db-0a809293effb.jpg",
      "https://static.percko.com/uploads/926bd630-0d67-4f16-a6f3-ecafc4ff4007.jpg",
      // "https://static.percko.com/uploads/539b9a87-0ffb-4559-a89b-688a9344f3eb.jpg",
    ],
  },
}

export const LyneHomeNewImageSliders = {
  Male: {
    BLUE: [
      {
        img:
          "https://static.percko.com/uploads/d2bc619a-4aa8-44c9-8172-be8e1699b7b7.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/f6d0781f-ab60-4848-8c2a-c6182471e120.jpg",
        alt: "",
        imageText: "De XS à XXXL",
      },
      {
        img:
          "https://static.percko.com/uploads/b1ea4460-197a-493a-80c2-468b6f49d826.jpg",
        alt: "",
        imageText: "Ceinture à scratch",
      },
      {
        img:
          "https://static.percko.com/uploads/5d75b4d3-3339-4527-99fb-536cfecfffbe.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/d21034a7-d763-44ab-97df-2afdf9475c8d.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/27c99644-7534-4be2-8b41-927ce42afede.jpg",
        alt: "",
        imageText: "",
      },
    ],
  },
  Female: {
    BLUE: [
      {
        img:
          "https://static.percko.com/uploads/7dd51325-e7ad-4e0c-bfca-985c5a505841.jpg",
        alt: "",
        imageText: "De XS à XXXL",
      },
      {
        img:
          "https://static.percko.com/uploads/00f88abf-4fb5-44a3-ae70-b98460b4fad6.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/7769cdab-c258-424b-a6d2-73a4fc38c7af.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/4a6e5a59-a112-424c-ab22-5f19e85fd98d.jpg",
        alt: "",
        imageText: "Ceinture à scratch",
      },
      {
        img:
          "https://static.percko.com/uploads/1b13df59-d14e-4b1a-8631-7aff8a12f0f0.jpg",
        alt: "",
        imageText: "",
      },
      {
        img:
          "https://static.percko.com/uploads/d8477e18-67b6-410b-a460-76e46dd2e336.jpg",
        alt: "",
        imageText: "",
      },
    ],
  },
}

export const LyneHomeImageSliders = {
  Male: {
    Noir: [
      {
        img:
          "https://static.percko.com/uploads/d8592fb4-bee9-4440-b16a-b552ec82a1c4.jpg",
        alt: "",
        imageText: "Mannequin de 1,85m, porte la taille XL",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/94d00b46-3238-4e75-b276-70d1f8436c91.jpg",
        alt: "",
        imageText: "De XS à XXXL",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/eebeb281-cecf-4836-8fbd-4f40b52f1348.jpg",
        alt: "",
        imageText: "Ceinture à scratch",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/3c0d0953-0816-437f-9fd9-f01f262043e7.jpg",
        alt: "",
        imageText: "",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_gilet_homme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/b31868a8-1a4a-4879-8c1b-c693412b20e9.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/1268a2e1-9182-49f4-8c7f-b1402b88385d.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
    ],
  },
  Female: {
    Noir: [
      {
        img:
          "https://static.percko.com/uploads/8d3936e0-ee96-4470-9471-6585aea4bb81.jpg",
        alt: "",
        imageText: "De XS à XXXL",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/e64b151f-026f-4430-b258-226f39c272ab.jpg",
        alt: "",
        imageText: "Mannequin de 1,77m, porte la taille XXL",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/a60ce394-6d08-49e8-9750-04a1bac37eac.jpg",
        alt: "",
        imageText: "Mannequin de 1,72m, porte la taille M",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/56884756-de24-4dcb-aea4-6eda614e449e.jpg",
        alt: "",
        imageText: "Ceinture à scratch",
        videoUrl:
          "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/shop/solutions_textiles_-_video_2_defile_-_gilet_femme.mp4",
      },
      {
        img:
          "https://static.percko.com/uploads/eb56efd9-d265-4667-a51f-95336dc53827.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
      {
        img:
          "https://static.percko.com/uploads/a639e051-3172-4065-ac33-77f2bab324b2.jpg",
        alt: "",
        imageText: "",
        videoUrl: "",
      },
    ],
  },
}

export const CapitalizeFirstLetter = string => {
  return string[0].toUpperCase() + string.slice(1)
}
