import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl, Link, navigate } from "gatsby-plugin-intl"
import { FaSearch } from "react-icons/fa"

const FaqSearchWrapper = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid #8e8e8e;
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  padding: 20px;
`

const InputWrapper = styled.div`
  width: 100%;
  position: relative;

  input {
    margin: 0px;
    width: 100%;
    padding: 15px 20px 15px 45px;
    box-shadow: none;
    border: 2px solid #8e8e8e;
    border-radius: 0px;
    font-size: 18px;
    color: #262626;
    font-family: "GothamLight";
  }

  input::placeholder {
    color: #666;
    opacity: 1;
  }
`

const SearchIconWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 12px;
`

const SearchListWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  width: 100%;
  background: #fff;
  border: 1px solid #8e8e8e;
  border-top: 0px;
  z-index: 2;
  max-height: 400px;
  overflow: auto;
`

const SearchListBox = styled.div``

const SearchListElement = styled.div`
  font-size: 16px;
  padding: 15px;
  cursor: pointer;

  :hover {
    background: #f1f1f1;
  }
`

const SearchListLink = styled.div`
  font-size: 16px;
  margin: 0px;
`

const SearchListTitle = styled.h4`
  font-size: 16px;
  margin: 0px;
`

const SearchListText = styled.div`
  font-size: 16px;
`

const FaqSearchSection = ({ intl }) => {
  const [faqTrans, setfaqTrans] = useState({})
  const [allFaqList, setallFaqList] = useState({})
  const [searchFaqList, setsearchFaqList] = useState({})
  const data = useStaticQuery(graphql`
    query FaqStickyQuery {
      allDirectusFaqPageTranslation {
        nodes {
          faq_search_title
          language
        }
      }
      allDirectusFaqListTranslation(
        filter: { title: { ne: null }, status: { eq: "published" } }
      ) {
        nodes {
          title
          faq_url
          content
          language
        }
      }
    }
  `)

  const Translation = () => {
    if (
      data &&
      data.allDirectusFaqPageTranslation.nodes.length > 0 &&
      data.allDirectusFaqListTranslation.nodes.length > 0
    ) {
      let node
      let allFaqList = []
      data.allDirectusFaqPageTranslation.nodes.forEach((item, index) => {
        if (item.language === intl.locale) {
          node = index
        }
      })
      data.allDirectusFaqListTranslation.nodes.forEach((faq, index) => {
        if (faq.language === intl.locale) {
          allFaqList.push(faq)
        }
      })
      if (node >= 0) {
        setfaqTrans(data.allDirectusFaqPageTranslation.nodes[node])
        setallFaqList(allFaqList)
      }
    }
  }

  function searchFaq(e) {
    var searchKey = e.target.value.toLowerCase()
    if (searchKey) {
      var searchList = allFaqList.filter(
        e =>
          e.title.toLowerCase().includes(searchKey) ||
          e.content.toLowerCase().includes(searchKey)
      )
      if (searchList) {
        setsearchFaqList(searchList)
      } else {
        setsearchFaqList("")
      }
    } else {
      setsearchFaqList("")
    }
  }

  useEffect(() => {
    Translation()
  }, [intl.locale])
  return (
    <FaqSearchWrapper>
      <Container>
        <InputWrapper>
          <input
            type="search"
            id="faq-search"
            name="faq_search"
            placeholder={
              Object.values(faqTrans).length > 0
                ? faqTrans.faq_search_title
                : ""
            }
            onChange={searchFaq}
          />
          <SearchIconWrapper>
            <FaSearch color="#8e8e8e" size={24} />
          </SearchIconWrapper>
          {searchFaqList && searchFaqList.length > 0 && (
            <SearchListWrapper>
              <SearchListBox>
                {searchFaqList.map((item, index) => {
                  return (
                    item && (
                      <SearchListElement
                        key={index.toString()}
                        onClick={() => {
                          setsearchFaqList("")
                          navigate(item.faq_url)
                        }}
                      >
                        {item.title && item.faq_url && (
                          <SearchListLink>
                            <SearchListTitle
                              dangerouslySetInnerHTML={{
                                __html: item.title,
                              }}
                            />
                          </SearchListLink>
                        )}
                        {/* {item.content && (
                        <SearchListText
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}
                        />
                      )} */}
                      </SearchListElement>
                    )
                  )
                })}
              </SearchListBox>
            </SearchListWrapper>
          )}
        </InputWrapper>
      </Container>
    </FaqSearchWrapper>
  )
}

export default injectIntl(FaqSearchSection)
