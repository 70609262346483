import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { generatePageURLNavigation } from "../../../../utils/additionalScriptHelpers";

const DropDownTshirts = ({ intl, onMouseLeave }) => {
  return (
    <ProductsDropdownEl>
      <DropDownList>
        <DropProductsSection>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/solutions-textiles/tshirt-discret/?Gender=Female&Collar=V&Color=Rose%20poudr%C3%A9&Size=&Offer=Seul")}
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              Femme
            </a>
          </li>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/solutions-textiles/tshirt-discret/?Gender=Female&Collar=V&Color=Rose%20poudr%C3%A9&Size=&Offer=Seul")}
            >
              T-shirt discret <sup>Best-seller</sup>
            </a>
          </li>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/solutions-textiles/tshirt-sport/?Gender=Female&Collar=Rond&Color=Noir&Size=&Offer=Seull")}
            >
              T-shirt de sport
            </a>
          </li>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/solutions-textiles/gilet-pratique/?Gender=Female&Collar=Rond&Color=Noir&Size=&Offer=Seul")}
            >
              Gilet pratique
            </a>
          </li>
        </DropProductsSection>
        <DropProductsSection>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/solutions-textiles/tshirt-discret/?Gender=Male&Collar=V&Color=Sable&Size=&Offer=Seul")}
              style={{ fontWeight: "bold", color: "black" }}
            >
              Homme
            </a>
          </li>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/solutions-textiles/tshirt-discret/?Gender=Male&Collar=V&Color=Sable&Size=&Offer=Seul")}
            >
              T-shirt discret <sup>Best-seller</sup>
            </a>
          </li>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/solutions-textiles/tshirt-sport/?Gender=Male&Collar=Rond&Color=Noir&Size=&Offer=Seul")}
            >
              T-shirt de sport
            </a>
          </li>
          <li>
            <a
              onClick={onMouseLeave}
              href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/solutions-textiles/gilet-pratique/?Gender=Male&Collar=Rond&Color=Noir&Size=&Offer=Seul")}
            >
              Gilet pratique
            </a>
          </li>
        </DropProductsSection>
      </DropDownList>
      <ImageWrapper>
        <ImageBox>
          <ImageUrl
           href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/solutions-textiles/tshirt-discret/?Gender=Female&Collar=V&Color=Rose%20poudré&Size=&Offer=Seul")}
          >
            T-shirt homme
          </ImageUrl>
          <Image
            className="lazyload"
            data-src="https://static.percko.com/uploads/dfbeb809-c23d-4efc-a3b6-6d25e6255957.jpg"
          />
          <TagWrapper>Best-seller</TagWrapper>
          <TextWrapper>T-shirt femme</TextWrapper>
        </ImageBox>
        <ImageBox>
          <ImageUrl
           href={generatePageURLNavigation(process.env.GATSBY_ACTIVE_ENV, intl.locale, "/shop/solutions-textiles/tshirt-discret/?Gender=Male&Collar=V&Color=Noir&Size=&Offer=Seul")}
          >
            T-shirt homme
          </ImageUrl>
          <Image
            className="lazyload"
            data-src="https://static.percko.com/uploads/def27a61-a073-4178-b87b-bb9087745d2e.jpg"
          />
          <TagWrapper>Best-seller</TagWrapper>
          <TextWrapper>T-shirt homme</TextWrapper>
        </ImageBox>
      </ImageWrapper>
    </ProductsDropdownEl>
  )
}

const ProductsDropdownEl = styled.div`
  display: flex;
  background: #fff;
  padding: 20px 30px;

  @media (max-width: 1200px) {
    padding: 0px 0px 20px 50px;
  }
`

const DropDownList = styled.div`
  width: 100%;
  min-width: 380px;
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

const DropProductsSection = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0px 20px 0px 0px;

  :last-of-type {
    margin: 0px;
  }

  li {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  li a sup {
    font-style: italic;
  }

  a {
    display: block;
    width: 100%;
    color: #000 !important;
    font-family: "Gotham Book";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    padding: 5px 10px;

    :hover {
      color: #000 !important;
      font-weight: bold;

      @media (max-width: 1200px) {
        font-weight: 500;
      }
    }
  }

  @media (max-width: 1200px) {
    margin: 0px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    display: none;
  }
`

const ImageBox = styled.div`
  width: 100%;
  max-width: 270px;
  display: flex;
  justify-content: center;
  object-fit: contain;
  position: relative;
  cursor: pointer;
  margin: 0px 25px 0px 0px;

  :last-of-type {
    margin-right: 0px;
  }
`

const ImageUrl = styled.a`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  text-indent: -9999px;
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
`

const TagWrapper = styled.div`
  width: auto;
  background: #fff;
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  padding: 5px 10px;
  position: absolute;
  top: 25px;
  left: 0px;
`

const TextWrapper = styled.div`
  width: auto;
  background: #fff;
  font-family: "Museo";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  padding: 5px 10px;
  position: absolute;
  bottom: 20px;
`

export default injectIntl(DropDownTshirts)
