import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import OffresIcon from "../../../../static/Clock.svg"

const OffreFlashStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  font-size: 18px;
  text-align: center;
  // background: ${props => props.theme.saleColor.bgColor};
  // color: ${props => props.theme.saleColor.textColor};
  background: #D7EDF6;
  color: #000;
  position: relative;
  overflow: hidden;
  min-height: 53px;
  display: flex;

  @media (max-width: 720px) {
    min-height: 50px;
  }

  @media (max-width: 480px) {
    min-height: 44px;
  }

  @media (max-width: 450px) {
    padding: 8px 10px;
  }

  @media (max-width: 350px) {
    min-height: 40px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const TextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 400;
  font-size: 21px;
  line-height: 1.2;
  text-align: left;
`

const LeftText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 900;
  font-size: 18px;
  line-height: 1.2;

  @media (max-width: 480px) {
    font-size: 16px;
  }

  @media (max-width: 380px) {
    font-size: 14px;
  }

  @media (max-width: 350px) {
    font-size: 13px;
  }
`

const RightText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;

  > span:nth-of-type(1) {
    margin: 0px 5px;
    font-size: 18px;
    font-family: "Museo";
    font-weight: 900;
    // padding: 5px 6px 4px;
    // background: ${props => props.theme.saleColor.textColor};
    // color: ${props => props.theme.saleColor.bgColor};

    @media (max-width: 480px) {
      font-size: 16px;
    }

    @media (max-width: 380px) {
      font-size: 14px;
      // padding: 4px 5px 3px;
    }

    @media (max-width: 350px) {
      font-size: 13px;
    }
  }

  > span:nth-of-type(2) {
    display: flex;

    @media (max-width: 600px) {
      // flex-direction: column;
    }
  }

  span > span {
    margin-left: 5px;

    @media (max-width: 600px) {
      margin-left: 0px;
    }
  }

  strong {
    font-family: "Museo";
    font-weight: 900;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }

  @media (max-width: 350px) {
    font-size: 13px;
  }

  @media (max-width: 330px) {
    font-size: 12px;
  }
`

const ClockImage = styled.img`
  display: flex;
  max-width: 25px;
  margin: 0px;
`

const OffreFlashStickyBanner = ({ intl }) => {
  return (
    <OffreFlashStickyBannerWrapper className="LyneHomeStickyBanner">
      <Container>
        <TextBox>
          <LeftText>
            {/* Derniers jours */}
            <ClockImage src={OffresIcon} />
          </LeftText>
          <RightText>
            <span>-10%</span>
            <span>
              supplémentaires{" "}
              <span>
                avec le code <strong>FLASH</strong>
              </span>
            </span>
          </RightText>
        </TextBox>
      </Container>
    </OffreFlashStickyBannerWrapper>
  )
}

export default injectIntl(OffreFlashStickyBanner)
